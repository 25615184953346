import React, { useEffect, useRef, useState } from 'react'
import Image from 'next/legacy/image'
import { contentfulImageLoader } from '@/utils/ImageLoaders'
import { ComponentDataBanner } from 'types/generated/contentful-types'

type SlidesProps = Pick<ComponentDataBanner, 'itemsCollection'>

const CountUp = ({ start = 0, end, duration, trigger }) => {
  const [value, setValue] = useState<number>(start)
  const counter = (end - start) / (duration / 50)
  const ref = useRef(start)

  const Count = () => {
    if (ref.current < end) {
      const result = Math.ceil(ref.current + counter)
      if (result > end) return setValue(end)
      setValue(result)
      ref.current = result
    } else {
      setValue(end)
    }
    setTimeout(Count, 50)
  }

  useEffect(() => {
    if (trigger) {
      Count()
    }
  }, [trigger])

  return <span className="fora-text-h6 number">{value}</span>
}

const DataBanner = ({ itemsCollection }: ComponentDataBanner): JSX.Element => {
  const sectionRef = useRef(null)
  const duration = 1000 // ms
  const delay = 25 // ms - Controls left to right load in of logos
  const [trigger, setTrigger] = useState<boolean>(false)

  const animStr = (i) =>
    `fadeIn ${duration}ms ease-out ${delay * (i + 1)}ms forwards`

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const elm = document.querySelectorAll<HTMLElement>('.data-item')
            if (elm.length > 0) {
              elm.forEach((el, i) => {
                el.style.animation = animStr(i)
                el.classList.add('content')
                setTrigger(true)
              })
            }
          }
        })
      },
      {
        threshold: 0.5,
      }
    )

    if (sectionRef.current) {
      observer.observe(sectionRef.current)
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current)
      }
    }
  }, [])

  if (!itemsCollection) return <></>

  const Slides = ({ itemsCollection }: SlidesProps): JSX.Element => {
    if (!itemsCollection) return <></>

    return (
      <div className="inline-flex py-4 border-t-2 border-b-2 logos-slider w-max xl:grid xl:grid-cols-12 xl:w-full border-darkShell">
        {itemsCollection.items.map((item, index) => (
          <div
            key={index}
            className="inline-flex items-center gap-4 justify-center m-3 h-8  md:h-20 md:w-[12vw] xl:w-auto"
          >
            <div className="w-8">
              <Image
                loader={() =>
                  contentfulImageLoader({
                    src: item?.icon?.url ?? '',
                    width: 32,
                    height: 32,
                    quality: 90,
                    fit: 'thumb',
                  })
                }
                src={item?.icon?.url ?? ''}
                alt={item?.icon?.description || `Fora - ${item?.icon?.url}`}
                layout="responsive"
                width={32}
                height={32}
                sizes="20vw"
                className="mix-blend-darken"
                data-src={item?.icon?.url}
                priority
              />
            </div>
            <div>
              <h4 className="fora-text-h6 !text-30 num !leading-105">
                {item?.number}
              </h4>
              <p className="font-serif text-base leading-140">{item?.title}</p>
            </div>
          </div>
        ))}

        <style jsx>{`
          @media screen and (max-width: 1279px) {
            @keyframes autoplay {
              from {
                transform: translate3d(0, 0, 0);
              }
              to {
                transform: translate3d(-100%, 0, 0);
              }
            }
            .logos-slider {
              backface-visibility: hidden;
              transform: translate3d(0, 0, 0);
              animation: ${itemsCollection.items.length * 2}s autoplay infinite
                linear;
            }
          }
        `}</style>
      </div>
    )
  }
  return (
    <>
      <section className="overflow-hidden xl:hidden whitespace-nowrap">
        <Slides itemsCollection={itemsCollection} />
        <Slides itemsCollection={itemsCollection} />
      </section>
      <section ref={sectionRef} className="hidden xl:block">
        <div className="border-b-2 border-t-2 border-darkShell h-[91px] flex justify-between px-5">
          {itemsCollection.items.map((item, index) => (
            <div
              key={index}
              className="flex items-center gap-4 opacity-0 data-item"
            >
              <div className="w-8 mx-auto">
                <Image
                  loader={() =>
                    contentfulImageLoader({
                      src: item?.icon?.url ?? '',
                      width: 32,
                      height: 32,
                      quality: 90,
                      fit: 'thumb',
                    })
                  }
                  src={item?.icon?.url ?? ''}
                  alt={item?.icon?.description || `Fora - ${item?.icon?.url}`}
                  layout="responsive"
                  width={32}
                  height={32}
                  sizes="20vw"
                  data-src={item?.icon?.url}
                />
              </div>
              <div>
                <CountUp
                  start={0}
                  end={item?.number}
                  trigger={trigger}
                  duration={duration}
                />
                {/* <span className="fora-text-h6 number" /> */}
                <p className="fora-text-body-3">{item?.title}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  )
}

export default DataBanner
